<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete v-model="country" :items="countries" :label="'País '+ ( required.includes('country') ? '*' : '')" :loading="country_loading" :rules="required.includes('country') ? countryRequired : []"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="state || city" class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete v-model="state_model" :items="states" :label="'Estado '+ ( required.includes('state') ? '*' : '')" :loading="state_loading" :rules="required.includes('state') ? stateRequired : []"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="city" class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete v-model="city_model" :items="cities" :label="'Municipio '+ ( required.includes('city') ? '*' : '')" :loading="city_loading" :rules="required.includes('city') ? cityRequired : []"></v-autocomplete>
            </v-col>
        </v-row>
    </div>    
</template>
<script>
    export default {
        name: 'country-autocomplete',
        props: ['state', 'city', 'presetvalue', 'required'],        
        data(){
            return {
                country: '',
                state_model: '',
                city_model: '',
                country_loading: false,
                state_loading: false,
                city_loading: false,
                countries: [],
                states: [],
                cities: [],
                countryRequired: [
                    v => !!v || 'País es requerido'
                ],
                stateRequired: [
                    v => !!v || 'Estado es requerido'
                ],
                cityRequired: [
                    v => !!v || 'Municipio es requerido'
                ],
            }
        },
        mounted(){
            this.getCountries()
        },
        methods: {
            getCountries(){
                this.country_loading = true

                this.$http.get(this.$store.state.countryApiRoute+'/api/getCountries')
                .then((response) => {
                    this.countries = response.data
                    this.country = 'MX'
                })
                .catch((error) => {
                    error
                })
                .finally(() => {
                    this.country_loading = false
                })
            },
            getStates(){
                this.state_loading = true
                
                this.$http.get(this.$store.state.countryApiRoute+'/api/getStates/'+this.country)
                .then((response) => {
                    this.states = response.data
                    this.state_model = 'SLP'
                })
                .catch((error) => {
                    error
                })
                .finally(() => {
                    this.state_loading = false
                })
            },
            getCities(){
                this.city_loading = true

                this.$http.get(this.$store.state.countryApiRoute+'/api/getCities/'+this.country+'/'+this.state_model)
                .then((response) => {
                    this.cities = response.data
                    this.city_model = 1842
                })
                .catch((error) => {
                    error
                })
                .finally(() => {
                    this.city_loading = false
                })
            },
            sendValue(){
                if(this.city){
                    if(this.city_model){
                        this.$emit('getValue', 'CT-'+this.city_model)
                    }
                }else if(this.state){
                    if(this.state_model){
                        this.$emit('getValue', 'ST-'+this.state_model)
                    }
                }else {
                    if(this.country){
                        this.$emit('getValue', 'CY-'+this.country)
                    }
                }
            },
            getStateDetails(){
                this.state_loading = true

                this.$http.get(this.$store.state.countryApiRoute+'/api/getStateDetails/'+this.state_model)
                .then((response) => {
                    // eslint-disable-next-line no-console
                    this.country = response.data.extra.country_code
                    this.getCountries()
                    this.getStates()
                })
                .catch((error) => {
                    error
                })
                .finally(() => {
                    this.city_loading = false
                })
            },
            getCityDetails(){
                this.city_loading = true

                this.$http.get(this.$store.state.countryApiRoute+'/api/getCityDetails/'+this.city_model)
                .then((response) => {
                    // eslint-disable-next-line no-console
                    this.country = response.data.city.country_code
                    this.state_model = response.data.city.state_code
                    this.getCountries()
                    this.getStates()
                    this.getCities()
                })
                .catch((error) => {
                    error
                })
                .finally(() => {
                    this.city_loading = false
                })
            },
            setEditionForm(){
                const type = this.presetvalue.substring(0, 2)
                const code = this.presetvalue.substring(3)
                
                switch(type){
                    case "CT":
                        this.city_model = parseInt(code)
                        this.getCityDetails()
                    break;
                    case "ST":
                        this.state_model = code
                        // this.city_model = parseInt(code)
                        this.getStateDetails()
                    break;
                    case "CY":
                        this.country = code
                        this.getCountries()
                    break;
                }
            }
        },
        watch: {
            country: function(){
                this.sendValue()
                if(this.state || this.city)
                    this.getStates()
            },
            state_model: function(){
                this.sendValue()
                if(this.city)
                    this.getCities()
            },
            city_model: function(){
                this.sendValue()
            },
            presetvalue: function(newVal, oldVal){
                if(oldVal === ''){
                    this.setEditionForm()
                    // eslint-disable-next-line no-console
                    console.log('se está actualizanda')
                    
                }
            }
            
        }
    }
</script>