<template>
    <v-dialog v-model="props.visible" :width="props.width" overlay-color="white">
        <v-card class="pb-3">
            <v-system-bar color="secondary" height="10px" dark></v-system-bar>
            <v-card-title class="headline justify-center">{{ props.title }}</v-card-title>
            <v-card-text v-if="props.text != null">{{ props.text }}</v-card-text>
            <v-card-text class="text-center">
                <div v-for="(btn, i) in props.buttons" :key="i">
                    <v-btn block class="mb-2" v-if="btn.method == null && btn.linkTo != null" rounded :color="btn.color" :class="btn.textColor" :text="btn.btnTypeText" :to="btn.linkTo" active-class="none">{{ btn.text }}</v-btn>
                    <v-btn block class="mb-2" v-else rounded :color="btn.color" :class="btn.textColor" :text="btn.btnTypeText" @click="method_handler(btn.method, btn.parameters)">{{ btn.text }}</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: ['props'],
        methods: {
            method_handler(method_name, parameters){
                this.$emit('modalResponse', {
                    method_name: method_name, 
                    parameters: parameters
                })
            }
        }
    }
</script>