<template>
    <div>
        <v-overlay :value="$store.state.overlay" z-index="7" color="primary" opacity="0.4">
                <!-- <v-card width="400px" color="transparent" flat="">
                    <v-card-text>
                        <v-row>
                            <v-progress-linear indeterminate color="secondary"></v-progress-linear>
                        </v-row>
                    </v-card-text>
                </v-card> -->
                <v-progress-circular indeterminate size="100" color="white"></v-progress-circular>
        </v-overlay>
  </div>
</template>

<script>
export default {
    name: 'loading-overlay'
}
</script>

<style>

</style>