<template>
    <div>
        <!-- <v-container>
            <v-row>
                <v-col cols :xl="12" :lg="12" :md="12" :sm="12">
                    <Chart :media="this.dataCollection" />
                </v-col>
                <v-col cols :xl="12" :lg="12" :md="12" :sm="12">
                    <Chart :media="this.dataCollectionOrdenVenta" />
                </v-col>
            </v-row>
        </v-container> -->
        
    </div>
</template>

<script>
    // import Chart from "@/components/Chart.vue";
    export default {
        // components:{
        //     Chart
        // },
        data(){
            return{
                //dataCollection: {},
                dataCollection: {
                    id:"cxp",
                    type: "bar",
                    title:"Ordenes de Compra",
                    data: {
                        labels:[],
                        datasets:
                            [{ // another line graph
                            label: 'Saldo',
                            data: [],
                            backgroundColor: [
                            //'rgba(71, 183,132,.5)', // Green
                            '#7503A6',
                            '#2405F2',
                            '#4227F2',
                            '#BFA004',
                            '#D97904',
                            '#7503A6',
                            '#2405F2',
                            '#4227F2',
                            '#BFA004',
                            '#D97904',
                            '#7503A6',
                            '#2405F2',
                            '#4227F2',
                            '#BFA004',
                            '#D97904',
                            '#7503A6',
                            '#2405F2',
                            '#4227F2',
                            '#BFA004',
                            '#D97904',
                            ]
                            }]
                    },
                    options: {
                        responsive: true,
                        lineTension: 1,
                        scales: {
                        yAxes: [{
                            ticks: {
                            beginAtZero: true,
                            padding: 25,
                            }
                        }]
                        },
                        legend: {
                            display: false
                        },
                        tooltips: {
                            callbacks: {
                                label: function(t, d) {
                                    var xLabel = d.datasets[t.datasetIndex].label;
                                    var yLabel = t.yLabel >= 1000 ? '$' + t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '$' + t.yLabel;
                                    return xLabel + ': ' + yLabel;
                                }
                            }
                        }
                    }
                },
                dataCollectionOrdenVenta: {
                    id:"cxv",
                    type: "bar",
                    title:"Ordenes de trabajo",
                    data: {
                        labels:[],
                        datasets:
                            [{ // another line graph
                            label: 'Saldo',
                            data: [],
                            backgroundColor: [
                            //'rgba(71, 183,132,.5)', // Green
                            '#7503A6',
                            '#2405F2',
                            '#4227F2',
                            '#BFA004',
                            '#D97904',
                            '#7503A6',
                            '#2405F2',
                            '#4227F2',
                            '#BFA004',
                            '#D97904',
                            '#7503A6',
                            '#2405F2',
                            '#4227F2',
                            '#BFA004',
                            '#D97904',
                            '#7503A6',
                            '#2405F2',
                            '#4227F2',
                            '#BFA004',
                            '#D97904',
                            ]
                            }]
                    },
                    options: {
                        responsive: true,
                        lineTension: 1,
                        scales: {
                        yAxes: [{
                            ticks: {
                            beginAtZero: true,
                            padding: 25,
                            }
                        }]
                        },
                        legend: {
                            display: false
                        },
                        tooltips: {
                            callbacks: {
                                label: function(t, d) {
                                    var xLabel = d.datasets[t.datasetIndex].label;
                                    var yLabel = t.yLabel >= 1000 ? '$' + t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '$' + t.yLabel;
                                    return xLabel + ': ' + yLabel;
                                }
                            }
                        }
                    }
                },
            }
        },
        mounted(){
            // this.getData();
            // this.getDataSaleOrders();
        },
        methods:{
            getData(){
                this.$store.state.overlay = true
                this.$http.get(this.$store.state.apiRoute+'/purchaseOrders')
                .then((response) => {
                    response.data.forEach(element => {
                        if(element.total > 0){
                            this.dataCollection.data.labels.push(element.supplier)
                            this.dataCollection.data.datasets[0].data.push((element.total*1.16).toFixed(2))
                        }
                    });
                })
                .catch(error => {
                    error
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            },
            getDataSaleOrders(){
                this.$store.state.overlay = true
                //alert('SaleOrder');
                this.$http.get(this.$store.state.apiRoute+'/saleOrders')
                .then((response) => {
                    response.data.forEach(element => {
                        //console.log(element);
                        if(element.total_details > 0){
                            this.dataCollectionOrdenVenta.data.labels.push(element.building);
                            this.dataCollectionOrdenVenta.data.datasets[0].data.push((element.total_details).toFixed(2));
                        }
                    });
                })
                .catch(error => {
                    error
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            },
            /*getDataPorCobrar(){
                this.$store.state.overlay = true
                this.$http.get(this.$store.state.apiRoute+'/getAccountsReceivable')
                .then((response) => {
                    response.data.forEach(element => {
                        if(element.subtotal > 0){
                            this.dataCollectionPorCobrar.data.labels.push(element.client_name)
                            this.dataCollectionPorCobrar.data.datasets[0].data.push((element.subtotal*1.16).toFixed(2))
                        }
                    });
                })
                .catch(error => {
                    error
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }*/

        }
    }
</script>

<style>

</style>