<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Nombre *" v-model="values.name" :rules="rules.name"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Descripción *" v-model="values.description" :rules="rules.description"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Precio *" v-model="values.price" :rules="rules.price"></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'producto-form',
    props: ['rules', 'values'],
    computed: {
        editingVariant(){
            return this.variantEdit !== null
        }
    },
    data(){
        return {
            
        }
    },
    methods: {
        save(){
            this.$emit('save')
        }
    }
}
</script>

<style>

</style>