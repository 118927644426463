const bgImage = {
  methods: {
    bgPortrait(image, extension) {
      let bg = null;
      if (image == null) {
        bg = "background-color:red;";
      } else {
        bg =
          "background-image: url(  " +
          require("@/assets/images/" + image + "." + extension) +
          ");";
      }
      return bg;
    },
  }
};

const image = {
  methods: {
    image(image, extension) {
      const defaultImage =
        "https://p7.hiclipart.com/preview/711/621/180/error-computer-icons-orange-error-icon.jpg"; // just set default
      //let path = folder;
      //var ext = browser(extension);
      try {
        return require("@/assets/images/" + image + "." + extension);
      } catch (e) {
        return defaultImage;
      }
    },
    imageParallax(image, extension) {
      const defaultImage =
        "https://p7.hiclipart.com/preview/711/621/180/error-computer-icons-orange-error-icon.jpg"; // just set default

      if (image != null && extension != null) {
        try {
          return require("@/assets/images/" + image + "." + extension);
        } catch (e) {
          return defaultImage;
        }
      } else {
        return null;
      }
    }
  }
};

export { bgImage, image };