import store from "../store";
import axios from 'axios'
import VueGatesPlugin from '../plugins/vue-gates';

export default function AuthMiddleware({to,next}) {
    
    console.log("auth riumn")
    // router.beforeEach((to, from, next) => {
        const token = localStorage.getItem("access_token_eme")
        if(token !== null && store.state.loggedIn){
            if(to.path == '/login'){
                next('/');
            }
            store.state.layout = 'dashboard'
            document.title = to.meta.title;
            
            next();
        }else{

            if(token !== null){
                axios.defaults.headers.common['Authorization'] = token
                axios.get(store.state.apiRoute+'/user')
                .then((response) => {
                    //$gates.setRoles(['writer']);
                   
                    if(!response.data){
                        store.state.loggedIn = false
                    }
                    else{
                       // console.log(response, 'ME');
                        store.state.loggedIn = true
                        store.state.loggedUsername = response.data.name;
                        store.state.userData = response.data;
                        VueGatesPlugin.init();
                        //console.log('here')
                    }
                    
                    if(store.state.loggedIn){
                        if(to.meta.auth){
                            store.state.layout = 'dashboard'
                            document.title = to.meta.title
                            next();
                        }else{
                            store.state.layout = 'dashboard'
                            next('/');
                        }
                    }else{
                        if(to.meta.auth){
                            store.state.layout = 'login'
                            next('login');
                        }else{
                            store.state.layout = 'login'
                            next()
                        }
                    }
                    
                })
                .catch((error) => {
                  console.log(error, to)
               
                    //   TokenManager.removeToken();
                      localStorage.removeItem("access_token_eme")
                      store.state.layout = 'login'
                      next('login')
                      return;
               
                })
            }else{
                if(to.meta.auth){
                    store.state.layout = 'login'
                    next('login')
                }else{
                    store.state.layout = 'login'
                    next()
                }
            }
        }


        // if(token !== null){
        //     //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        //     axios.defaults.headers.common['Authorization'] = token
        //     axios.get(store.state.apiRoute+'/user')
        //     .then((response) => {
        //     if(!response.data){
        //                 store.state.loggedIn = false
        //             }
        //             else{
        //         store.state.loggedIn = true
        //         store.state.loggedUsername = response.data.name
        //             }
        //     if(store.state.loggedIn){
        //         if(to.meta.auth){
        //         store.state.layout = 'dashboard'
        //         document.title = to.meta.title
        //         next()
        //         }else{
        //         store.state.layout = 'dashboard'
        //         next('dashboard')
        //         }
        //     }else{
        //         if(to.meta.auth){
        //         store.state.layout = 'login'
        //         next('login')
        //         }else{
        //         store.state.layout = 'login'
        //         next()
        //         }
        //     }
        //     })
        //     .catch((error) => {
        //     console.log(error, to)
        //     })
        // }else{
        //     if(to.meta.auth){
        //     store.state.layout = 'login'
        //     next('login')
        //     }else{
        //     store.state.layout = 'login'
        //     next()
        //     }
        // }
    // })
}


