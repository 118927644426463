<template>
    <div>
        <v-card flat>
            <v-card-title>
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="4">
                        <h3>Detalles del producto</h3>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row justify="center" no-gutters>
                    <v-col cols="12" md="6" lg="4">
                        <details-card :detailsItems="detailsItems"></details-card>        
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                 <v-row justify="center">
                    <v-col cols="12" md="6" lg="4" class="text-right">
                        <v-btn color="secondary" tile :to="{ name: 'Productos' }">Regresar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    data(){
        return {
            id: this.$route.params.id,
            detailsItems: [
                {
                    title: null, 
                    items: [
                        { concepto: 'Nombre', index: 'name', valor: null },
                        { concepto: 'Tipo de materia', index: 'type', valor: null },
                        { concepto: 'Descripción', index: 'description', valor: null },
                        { concepto: 'Marca', index: 'brand', valor: null },
                        { concepto: 'Unidad', index: 'unit_id', valor: null },
                        
                    ],
                },
            ]
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.$http.get(this.$store.state.apiRoute+'/bigProducts/'+this.id+'/edit')
            .then((response) => {
                this.detailsItems.forEach((x) => {
                    x.items.forEach((y) => {
                        y.valor = response.data.product[y.index]
                    })
                })

                let suppliers = []
                let variants = []

                response.data.suppliers.forEach((x) => {
                    suppliers.push({
                        concepto: null, 
                        index: 'supplier',
                        valor: x.name
                    })
                })
                response.data.variants.forEach((x) => {
                    variants.push({
                        concepto: null, 
                        index: 'variant',
                        valor: x.size+' | '+x.color+' | '+x.price
                    })
                })

                this.detailsItems.push({
                    title: 'Proveedores',
                    items: suppliers,
                })

                this.detailsItems.push({
                    title: 'Variantes',
                    items: variants
                })
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        }
    }
}
</script>

<style>
</style>