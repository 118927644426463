import Vue from 'vue'
import VueRouter from 'vue-router';
import store from '../store';
import {routes} from "./routes";


Vue.use(VueRouter)



const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
    
  //AuthMiddleware({ to, from, next })
//   console.log);
    store.state.loading = false;
    to.meta.prevRoute = from;
    if (!to.meta.middlewares) {
        return next()
    }
    const middlewares = to.meta.middlewares
    Object.keys(middlewares).forEach(middleware => {
        middlewares[middleware]({ to, from, next })
    })
    //return next()
})

export default router


