import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import "./components"
import VueGates from 'vue-gates';

Vue.config.productionTip = false
Vue.prototype.$http = axios


Vue.use(VueGates);


// router.beforeEach((to, from, next) => {
//     const lsToken = localStorage.getItem("access_token_eme")
//     if(lsToken !== null){
//       //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//       axios.defaults.headers.common['Authorization'] = lsToken
//       axios.get(store.state.apiRoute+'/user')
//       .then((response) => {
//         if(!response.data){
//                   store.state.loggedIn = false
//               }
//               else{
//           store.state.loggedIn = true
//           store.state.loggedUsername = response.data.name
//               }
//         if(store.state.loggedIn){
//           if(to.meta.auth){
//             store.state.layout = 'dashboard'
//             document.title = to.meta.title
//             next()
//           }else{
//             store.state.layout = 'dashboard'
//             next('dashboard')
//           }
//         }else{
//           if(to.meta.auth){
//             store.state.layout = 'login'
//             next('login')
//           }else{
//             store.state.layout = 'login'
//             next()
//           }
//         }
//       })
//       .catch((error) => {
//         console.log(error, to)
//       })
//     }else{
//       if(to.meta.auth){
//         store.state.layout = 'login'
//         next('login')
//       }else{
//         store.state.layout = 'login'
//         next()
//       }
//     }
// })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
